import { ReactNode } from "react";
import { useToggle } from "@uidotdev/usehooks";
import tw from "tailwind-styled-components";

import ChevronLeft from "svg/chevron-left.svg";

const Content = tw.div<{ $expanded?: boolean }>`
  ${({ $expanded }) => ($expanded ? "block h-auto" : "hidden")}
`;

const Arrow = tw.div<{ $expanded?: boolean }>`
  transition
  duration-300
  ease-in-out
  mr-4
  ${({ $expanded }) => ($expanded ? "rotate-90" : "-rotate-90")}
`;

type ExpandableContentProps = {
  header: ReactNode;
  children: ReactNode;
  openByDefault?: boolean;
  onToggle?: (open: boolean) => void;
};

export default function ExpandableContent({
  header,
  children,
  openByDefault,
  onToggle,
}: ExpandableContentProps) {
  const [open, toggle] = useToggle(openByDefault || false);

  function handleClick() {
    toggle(!open);

    if (onToggle) {
      onToggle(!open);
    }
  }

  return (
    <div>
      <button onClick={handleClick} className="flex items-center">
        <Arrow $expanded={open}>
          <ChevronLeft className="h-4 w-4" />
        </Arrow>

        {header}
      </button>

      <Content $expanded={open}>{children}</Content>
    </div>
  );
}
