import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useEffect,
  useRef,
  useCallback,
} from "react";

import {
  initFacebookPixel,
  trackEvent as trackFBEvent,
} from "./facebook-pixel";

type FacebookPixelContextType = {
  trackEvent: (event: { event: string; data?: Record<string, any> }) => void;
};

const FacebookPixelContext = createContext<
  FacebookPixelContextType | undefined
>(undefined);

export const FacebookPixelProvider: FC<{
  children: ReactNode;
  pixelId: string;
}> = ({ children, pixelId }) => {
  const isPixelInitialized = useRef(false);
  const eventQueue = useRef<
    { event: string; eventId?: string; data?: Record<string, any> }[]
  >([]);

  useEffect(() => {
    initFacebookPixel(pixelId).then(() => {
      isPixelInitialized.current = true;
      // Process any queued events once Pixel is initialized
      eventQueue.current.forEach((e) => trackFBEvent(e));
      eventQueue.current = []; // Clear the queue after processing
    });
  }, [pixelId]);

  const trackEvent = useCallback(
    (event: { event: string; data?: Record<string, any> }) => {
      if (!isPixelInitialized.current) {
        // Queue the event if Pixel is not initialized
        eventQueue.current.push(event);
      } else {
        trackFBEvent(event);
      }
    },
    []
  );

  return (
    <FacebookPixelContext.Provider value={{ trackEvent }}>
      {children}
    </FacebookPixelContext.Provider>
  );
};

export const useFacebookPixel = () => {
  const context = useContext(FacebookPixelContext);
  if (context === undefined) {
    throw new Error(
      "useFacebookPixel must be used within a FacebookPixelProvider"
    );
  }
  return context;
};
