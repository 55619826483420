import {
  OnboardingContainer,
  OnboardingContent,
  OnboardingPanel,
  OnboardingPanelUnder,
  OnboardingHeader,
} from "layouts/OnboardingLayout";

export default function QuotePackage() {
  return (
    <OnboardingContainer>
      <OnboardingHeader className="sm:mt-8 sm:text-3xl">
        Great, let's get you setup
      </OnboardingHeader>

      <div className="grid sm:grid-cols-3 grid-cols-1 sm:gap-4">
        <OnboardingContent className="w-full grid grid-cols-1 gap-4 sm:gap-6 auto-rows-min sm:col-span-2">
          <div className="text-center">
            <h2>First, lets verify you're within our service area</h2>

            <form>
              <input type="text" name="zip" required />
            </form>
          </div>
        </OnboardingContent>
      </div>
    </OnboardingContainer>
  );
}
