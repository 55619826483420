import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import equal from "fast-deep-equal/react";
import { useCallback, useRef } from "react";

import config from "config";

class NetworkError extends Error {
  statusCode: number;
  responseData: any;

  constructor(statusCode: number, responseData?: any) {
    super();
    this.statusCode = statusCode;
    this.responseData = responseData;
  }
}

function api(requestOptions = {}) {
  return async (url: string, options?: any) => {
    const fetchOptions = {
      ...{
        headers: {},
      },
      ...requestOptions,
      ...(options ? { body: JSON.stringify(options.arg) } : {}),
    };

    const response = await fetch(
      `${config.apiEndpoint}/api/v1${url}`,
      fetchOptions
    );

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.indexOf("application/json") >= 0) {
      const json = await response.json().catch(() => response);

      if (!response.ok) {
        throw new NetworkError(response.status, json);
      }

      return json;
    }

    if (!response.ok) {
      throw new NetworkError(response.status);
    }

    return response;
  };
}

// TODO: Don't use any for route type
export function useGET(route: any, requestOptions?: any, swrOptions?: any) {
  const response = useSWR(route, api(requestOptions), swrOptions);

  return response;
}

export function usePOST(route: string, swrOptions?: any) {
  const response = useSWRMutation(
    route,
    api({
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }),
    swrOptions
  );

  return response;
}

export function usePUT(route: string, swrOptions?: any) {
  const response = useSWRMutation(
    route,
    api({
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    }),
    swrOptions
  );

  return response;
}

export function useDELETE(route: string, swrOptions?: any) {
  const response = useSWRMutation(
    route,
    api({
      method: "DELETE",
    }),
    swrOptions
  );

  return response;
}
