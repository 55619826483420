import { Link } from "react-router-dom";
import tw from "tailwind-styled-components";
import { Helmet } from "react-helmet-async";

import Footer from "components/Footer";

export const OnboardingContainer = tw.div`
  mt-4
  mb-16
`;

export const OnboardingContent = tw.div`
  mt-3
  sm:mt-8
  mx-auto
  px-3
  sm:px-4
`;

export const OnboardingPanel = tw.div`
  bg-white
  border
  rounded-xl
  p-3
  sm:p-4
`;

export const OnboardingPanelUnder = tw.div`
  mt-8
`;

export const OnboardingHeader = tw.h1`
  pt-2
  mt-2
  px-3
  sm:px-4
  text-center
  text-2xl
  font-bold
  font-serif
`;

export const OnboardingSubHeader = tw.h3`
  mb-8
  px-3
  sm:px-4
  text-center
  text-neutral-500
`;

type OnboardingLayoutProps = {
  children?: React.ReactNode;
};

const OnboardingLayout = ({ children }: OnboardingLayoutProps) => {
  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#FBF6D9" />
      </Helmet>

      <div className="min-h-screen">
        <main className="container mx-auto">{children}</main>

        <Footer />
      </div>
    </>
  );
};

export default OnboardingLayout;
