import type { FirebaseApp } from "firebase/app";
import type { ReactNode } from "react";

import { createContext } from "react";

// TODO: Should be initialize analytics here too?

export const FirebaseContext = createContext<FirebaseApp | undefined>(
  undefined
);

type ProviderProps = {
  children: ReactNode;
  app: FirebaseApp;
};

export function FirebaseProvider({ children, app }: ProviderProps) {
  return (
    <FirebaseContext.Provider value={app}>{children}</FirebaseContext.Provider>
  );
}
